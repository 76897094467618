import { createAction } from 'redux-actions';
import { isEmpty } from 'lodash-es';
import HelpHero from 'helphero';
import { batch } from 'react-redux';

import { GlobalState } from '../../rootReducer';
import {
    BaseSearch,
    CompanyDataDTO,
    Currency,
    GroupMemberCommonDTO,
    GroupMemberDTO,
    UserSetting,
    VatCodeDTO,
    CustomCostObjectiveFullDTO,
    CompanyInvoiceCountDTO,
    SortDirection,
    PagingOptions,
} from '../../services/types/ApiTypes';
import { createRequest } from './userSettingUtil';
import { User } from '../../services/ApiClient';
import api from '../../services/ApiServices';
import { DispatchThunk } from '../../storeConfig';
import { BackOfficeCompanyDTO, BackOfficeCompanyDTOExtended } from '../../services/types/BoApiTypes';
import i18nInstance from '../../i18n';
import { numericSortCodes } from '../utils/numericSort';
import { loadableDataActions } from '../utils/LoadableData';
import { notify } from '../utils/notify';

const ns = 'user/';

export const getCurrenciesActions = loadableDataActions<undefined, Currency[]>(`${ns}GET_CURRENCIES`);
export const getUserCompaniesActions = loadableDataActions<undefined, BackOfficeCompanyDTOExtended[]>(`${ns}GET_USER_COMPANIES`);
export const getGroupMemberActions = loadableDataActions<undefined, GroupMemberDTO>(`${ns}GET_GROUP_MEMBER`);
export const getCommonGroupMemberActions = loadableDataActions<undefined, GroupMemberCommonDTO>(`${ns}GET__COMMON_GROUP_MEMBER`);

export const setUserCompaniesSearchResultAction = createAction<BackOfficeCompanyDTOExtended[]>(`${ns}SET_USER_COMPANIES_SEARCH_RESULT`);
export const setNoMoreCompaniesSearchResultsAction = createAction<boolean>(`${ns}SET_NO_MORE_USER_COMPANIES_SEARCH_RESULTS`);
export const setCurrentUserAction = createAction<User>(`${ns}SET_CURRENT_USER`);
export const setCurrentCompanyAction = createAction<CompanyDataDTO>(`${ns}SET_CURRENT_COMPANY`);
export const setAccountsAction = createAction<any>(`${ns}SET_ACCOUNTS`);
export const setCustomCostObjectivesAction = createAction<any>(`${ns}SET_FIELD_TEMPLATES`);
export const setUserCompaniesAction = createAction<BackOfficeCompanyDTOExtended[]>(`${ns}SET_USER_COMPANIES`);
export const setUserActiveVatCodesAction = createAction<VatCodeDTO[]>(`${ns}SET_USER_ACTIVE_VAT_CODES`);

export const updateWaitingInvoicesCountAction = createAction<{ companyGuid: string; count: number }>(`${ns}UPDATE_WAITING_INVOICES_COUNT`);
export const removeUserCompanyAction = createAction<{ companyGuid: string }>(`${ns}REMOVE_USER_COMPANY`);
export const getAllInvoiceCountsAction = createAction<CompanyInvoiceCountDTO[]>(`${ns}SET_WAITING_INVOICE_COUNTS`);
export const getInvoiceCountsForCompanyListAction = createAction<CompanyInvoiceCountDTO[]>(`${ns}SET_WAITING_INVOICE_COUNTS_FOR_COMPANY_LIST`);
export const areWaitingInvoicesCountUpdatingAction = createAction<{ areWaitingInvoicesCountsUpdating: boolean }>(`${ns}ARE_WAITING_INVOICES_COUNT_UPDATING`);
export const updateCostObjectivesFilterLastDateAction = createAction<{ costObjectivesFilterLastDate: string }>(`${ns}COST_OBJECTIVES_FILTER_LAST_DATE`);
export const updateCustomCostObjectivesFilterLastDateAction = createAction<{ customCostObjectivesFilterLastDate: string }>(`${ns}CUSTOM_COST_OBJECTIVES_FILTER_LAST_DATE`);

export const setGroupMemberAction = createAction<GroupMemberDTO>(`${ns}SET_GROUP_MEMBER`);
export const setUserCompaniesNotificationsLoadMorePagingOptionsAction = createAction<PagingOptions>(`${ns}SET_USER_COMPANIES_NOTIFICATIONS_PAGING_OPTIONS`);
export const setUserCompaniesTotalCount = createAction<number>(`${ns}SET_USER_COMPANIES_TOTAL_COUNT`);

export function getCurrentUser() {
    return async (dispatch: DispatchThunk) => {
        try {
            const response = await api.user.getCurrentUser();
            dispatch(setCurrentUserAction(response.data));
        } catch (e) {
            console.error(e);
        }
    };
}

export function getCurrencies() {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            if (getState().user.currencies.length) {
                return;
            }
            dispatch(getCurrenciesActions.request(undefined));
            const response = await api.currency.getAll();
            dispatch(getCurrenciesActions.success(response.data));
        } catch (e) {
            console.error(e);
            dispatch(getCurrenciesActions.error(e));
        }
    };
}

export function getUserCompanies() {
    return async (dispatch: DispatchThunk) => {
        try {
            const searchParams: BaseSearch = createRequest('', 'CompanyName', 1, 25);
            dispatch(getUserCompaniesActions.request(undefined));
            const response = await api.user.getUserCompanies(searchParams);
            const companies = response?.data;

            batch(() => {
                dispatch(setUserCompaniesNotificationsLoadMorePagingOptionsAction(searchParams.PagingOptions));
                dispatch(getUserCompaniesActions.success(companies?.Items || []));
                dispatch(setUserCompaniesAction(companies?.Items || []));
                dispatch(setUserCompaniesTotalCount(companies?.TotalCount || 0));
            });

            dispatch(updateAllWaitingInvoiceCounts(companies?.Items || [], true));
        } catch (e) {
            console.error(e);
            dispatch(getUserCompaniesActions.error(e));
        }
    };
}

function addInvoiceCountToCompanies(companies: BackOfficeCompanyDTO[], counts: CompanyInvoiceCountDTO[]) {
    const companiesWithCounts: BackOfficeCompanyDTOExtended[] = [];
    companies.forEach((comp) => {
        const countObj = counts.find((count) => count.CompanyGuid === comp.CompanyGuid);
        // if no countObj found, it means the count is 0 (BE returns only existing counts CompanyInvoiceCountDTO-s)
        companiesWithCounts.push({ ...comp, WaitingInvoiceCount: countObj?.Count || 0 });
    });
    return companiesWithCounts;
}

export function searchUserCompanies(searchValue: string) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const searchParams: BaseSearch = {
                ...createRequest(searchValue, null, 1, 25),
            };
            const response = await api.user.getUserCompanies(searchParams);
            const companies = response?.data;
            isEmpty(companies) && dispatch(setNoMoreCompaniesSearchResultsAction(true));
            const newCountsResponse = isEmpty(companies) ? undefined : await api.company.getWaitingInvoicesCountsForCompanyList(companies?.Items?.map((c) => c.CompanyGuid));
            const newCompaniesWithCounts = addInvoiceCountToCompanies(companies?.Items || [], newCountsResponse?.data);
            // if lastCompanyGuid, we are fetching next chunk of companies for infite scroll
            const oldCompaniesWithCounts = getState().user.userCompaniesSearchResult;
            dispatch(setUserCompaniesSearchResultAction([...oldCompaniesWithCounts, ...newCompaniesWithCounts]));
        } catch (e) {
            console.error(e);
        }
    };
}

export function getUserVATCodes() {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        if (getState().user.vatCodes.length) {
            return;
        }
        try {
            const searchParams: BaseSearch = {
                PagingOptions: {
                    Count: 10000,
                    Page: 1,
                },
                Restrictions: [],
                SortItems: [],
            };
            const response = await api.user.getActiveVatCodesForUser(searchParams);
            const codes = response.data.Items.sort(numericSortCodes);
            dispatch(setUserActiveVatCodesAction(codes));
        } catch (e) {
            console.error(e);
        }
    };
}

export function getAllInvoiceCounts() {
    return async (dispatch: DispatchThunk) => {
        try {
            const response = await api.company.getWaitingInvoicesCounts();
            dispatch(getAllInvoiceCountsAction(response.data));
        } catch (e) {
            console.error(e);
        }
    };
}

export function updateAllWaitingInvoiceCounts(companies?: BackOfficeCompanyDTOExtended[], updateWithGetCompanies?: boolean) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            if (isEmpty(getState().user.groupMemberCommonLoadable.payload)) {
                await dispatch(getCurrentUserGroupMember());
            }
            const state = getState();
            companies = companies || state.user.userCompanies;
            if (!companies) {
                return;
            }
            const {
                groupMemberCommonLoadable: { payload: groupMember },
            } = state.user;

            dispatch(areWaitingInvoicesCountUpdatingAction({ areWaitingInvoicesCountsUpdating: true }));

            for (const company of companies) {
                dispatch(
                    updateWaitingInvoicesCountAction({
                        companyGuid: company.CompanyGuid,
                        count: undefined,
                    }),
                );
            }

            if (isEmpty(state.user.allInvoiceCounts) || !updateWithGetCompanies) {
                await dispatch(getAllInvoiceCounts());
            }

            for (const company of companies) {
                // can sometimes have an empty user and pollute console log
                if (groupMember) {
                    const count = getState().user.allInvoiceCounts.find((p) => p.CompanyGuid === company.CompanyGuid);
                    dispatch(
                        updateWaitingInvoicesCountAction({
                            companyGuid: company.CompanyGuid,
                            count: count?.Count || 0,
                        }),
                    );
                }
            }
        } catch (e) {
            console.error(e);
        } finally {
            dispatch(areWaitingInvoicesCountUpdatingAction({ areWaitingInvoicesCountsUpdating: false }));
        }
    };
}

export function updateSingleCompanyWaitingInvoiceCount(company: BackOfficeCompanyDTOExtended) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const state = getState();
            const groupMember = state.user.groupMemberCommonLoadable.payload;
            if (!groupMember) {
                return;
            }
            dispatch(
                updateWaitingInvoicesCountAction({
                    companyGuid: company.CompanyGuid,
                    count: undefined,
                }),
            );
            const countResponse = await api.company.getWaitingInvoicesCount(company.DbName, groupMember.UserGuid, company.CompanyGuid);
            dispatch(
                updateWaitingInvoicesCountAction({
                    companyGuid: company.CompanyGuid,
                    count: countResponse.data,
                }),
            );
        } catch (e) {
            console.error(e);
        }
    };
}

export function getCurrentUserGroupMember(forceFetch?: boolean) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const currentGMLoadable = getState().user.groupMemberCommonLoadable;

            /**
             * need additional check to see if GM of current user is up-to-date
             */
            if (
                !forceFetch &&
                !isEmpty(currentGMLoadable.payload) &&
                !currentGMLoadable.loading &&
                !!currentGMLoadable.payload &&
                currentGMLoadable.payload.Id === getState().user.currentUser.GroupMemberId
            ) {
                return currentGMLoadable.payload;
            }
            !getState().user.currentUser && (await dispatch(getCurrentUser()));

            await dispatch(initHelpHero());

            dispatch(getCommonGroupMemberActions.request(undefined));
            const response = await api.user.getCommonGroupMember(getState().user.currentUser.GroupMemberId);
            dispatch(getCommonGroupMemberActions.success(response.data));
            return response.data;
        } catch (e) {
            dispatch(getCommonGroupMemberActions.error(e));
            return null;
        }
    };
}

export function initHelpHero() {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            !getState().user.currentUser && (await dispatch(getCurrentUser()));
            const user = getState().user.currentUser;
            if (user.BOGuid != null) {
                const helphero = HelpHero(process.env.REACT_APP_HELPHERO_KEY);
                helphero.identify(user.BOGuid, {
                    //name: response.data.FullName,
                    role: user.MemberRoles.join(', '),
                    isAdmin: user.MemberRoles.includes(0) || user.MemberRoles.includes(101),
                });
            }
        } catch (e) {
            console.error('Usertour error: ' + e);
        }
    };
}

/**
 * A separate request for simply updating the currentUser Settings with list views sorting and paging preferences
 */
export function updateUserSettings(groupMemberId: number, userSettings: UserSetting[]) {
    return async () => {
        try {
            await api.user.updateUserSettings(groupMemberId, userSettings);
        } catch (e) {
            console.error(e);
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'), i18nInstance.t('interceptorsFactory.Error'));
        }
    };
}

export function updateCustomCostObjectiveAction(costObjective: CustomCostObjectiveFullDTO) {
    return (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState().user;
        if (!state.customCostObjectives.length) {
            return;
        }
        const customCostObjectives = [...state.customCostObjectives];
        const modifiedIndex = customCostObjectives.findIndex((p) => p.Id === costObjective.Id);
        if (modifiedIndex === -1) {
            return;
        }
        customCostObjectives[modifiedIndex] = costObjective;
        dispatch(setCustomCostObjectivesAction(customCostObjectives));
    };
}

export const updateCustomCostObjectiveArray = (accountingDate: string) => {
    return async (dispatch: DispatchThunk) => {
        const searchParams: BaseSearch = {
            Restrictions: [],
            SortItems: [
                {
                    SortColumn: 'Code',
                    SortDirection: SortDirection.Asc,
                },
            ],
            PagingOptions: {
                Page: 1,
                Count: 10000,
            },
        };
        const response = await api.user.getCostObjectivesAndItemsCountForUser(searchParams, true, accountingDate);
        dispatch(setCustomCostObjectivesAction(response.data.Items));
    };
};

export function getCurrentCompanyData() {
    return async (dispatch: DispatchThunk) => {
        try {
            const response = await api.company.getCompanyData();
            dispatch(setCurrentCompanyAction(response.data));
        } catch (e) {
            console.error(e);
        }
    };
}

export const loadMoreCompaniesNotificationsSettings = () => async (dispatch: DispatchThunk, getState: () => GlobalState) => {
    try {
        const user = getState().user;
        const { Page, Count } = user.userCompaniesNotificationsPagingOptions;
        const companiesTotalCount = user.userCompaniesTotalCount;
        const currentCompaniesList = user.userCompanies;
        const currentCompaniesCount = currentCompaniesList?.length;
        if (companiesTotalCount <= currentCompaniesCount) {
            return;
        }
        const searchParams: BaseSearch = createRequest('', 'CompanyName', Page + 1, Count);
        dispatch(setUserCompaniesNotificationsLoadMorePagingOptionsAction(searchParams.PagingOptions));
        dispatch(getUserCompaniesActions.request(undefined));
        const response = await api.user.getUserCompanies(searchParams);
        const companies = response?.data;
        companies.Items = [...currentCompaniesList, ...companies?.Items];

        batch(() => {
            dispatch(getUserCompaniesActions.success(companies?.Items || []));
            dispatch(setUserCompaniesAction(companies?.Items || []));
            dispatch(setUserCompaniesTotalCount(companies?.TotalCount || 0));
        });

        // dispatch(updateAllWaitingInvoiceCounts(companies?.Items || [], true));
    } catch (e) {
        console.error(e);
        dispatch(getUserCompaniesActions.error(e));
    }
};

export const resetUserCompaniesNotificationsPagingOptions = () => (dispatch: DispatchThunk) => {
    dispatch(getUserCompanies());
};
