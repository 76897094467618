import { createAction } from 'redux-actions';

import { GlobalState } from '../../../../../rootReducer';
import i18nInstance from '../../../../../i18n';
import { SystemConfigurationDTO } from '../../../../../services/types/ApiTypes';
import { loadableDataActions } from '../../../../../common/utils/LoadableData';
import api from '../../../../../services/ApiServices';
import { DispatchThunk } from '../../../../../storeConfig';
import { notify } from '../../../../../common/utils/notify';
import { isSuf } from '../../../../../common/utils/whitelabelHelper';
import { authorizationDataKey, setCurrentCompanySettings } from '../../../../../common/company/CompanyActions';

const ns = 'company-settings-list/';

export const getCompanySettingsData = loadableDataActions<string, SystemConfigurationDTO[]>(`${ns}GET_COMPANY_SETTINGS`);

export const updateSetting = createAction<{ result: SystemConfigurationDTO; itemToUpdate?: string }>(`${ns}UPDATE_COMPANY_SETTING`);

export function updateCompanySetting(companySetting: SystemConfigurationDTO) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            companySetting.ModifiedBy = null;
            if (companySetting?.Name) {
                dispatch(
                    updateSetting({
                        result: companySetting,
                        itemToUpdate: companySetting?.Name,
                    }),
                );
            }

            const result = await api.company.addOrUpdateCompanySetting(companySetting, companySetting?.Name === 'ExportCSVDelimiterSymbol');

            if (!companySetting?.Name) {
                dispatch(
                    updateSetting({
                        result: result.data,
                    }),
                );
            }
            dispatch(setCurrentCompanySettings(getState().companySettingsList.companySettingsDataLoadable.payload));
            notify.success(i18nInstance.t('views.CompanySettings.Update.SuccessText'));
        } catch (e) {
            console.error(e);
            notify.error(i18nInstance.t('interceptorsFactory.ErrorWhileProcessingData'));
        }
    };
}

export function getCompanySettings(companyGuid: string) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const authData = JSON.parse(localStorage.getItem(authorizationDataKey));

            dispatch(getCompanySettingsData.request(companyGuid));

            const response = await api.company.getCompanySettings(getState().changeCompany?.activeCompanyGuid || authData?.companyGuid);

            const isBoAdmin = JSON.parse(localStorage.getItem(authorizationDataKey))?.isBoAdmin;
            //hide settings that are not yet implemented
            let companySettingsList = response.data?.filter((e) => {
                return ![
                    'isInvoiceRegisterModulActive',
                    'IsPurchaseOrdersModulActive',
                    'IsProductItemsModulActive',
                    'IsArchiveModulActive',
                    'IsExpensesModuleActive',
                    'PurchaseOrderPrefix',
                    'CurrentPurchaseOrderNumber',
                    'PurchaseOrderSuffix',
                    'CombinedRowName',
                    'FirstTransactionRowName',
                    'IsMobileOCRModulActive',
                    'IsDigitizationModulActive',
                    'IsBypassFitekinModuleActive',
                    'IsAiAccountingModuleActive',
                    'IsSyncSuppliersModuleActive',
                ].includes(e.Name);
            });

            if (!isBoAdmin) {
                companySettingsList = companySettingsList?.filter((e) => {
                    return e.Name !== 'CustomLoginUrl';
                });
            }

            if (isSuf() && process.env.NODE_ENV === 'production') {
                companySettingsList = companySettingsList?.filter((e) => {
                    return e.Name !== 'NotifyExternalApiInvoice' && e.Name !== 'NotifyExternalApi' && e.Name !== 'NotifyExternalApiPurchaseOrder';
                });
            }

            dispatch(getCompanySettingsData.success(companySettingsList));
        } catch (e) {
            console.error(e);
            dispatch(getCompanySettingsData.error(e));
        }
    };
}
