import React, { useState, useEffect, useMemo } from 'react';
import { isEqual } from 'lodash-es';
import { withTranslation, WithTranslation } from 'react-i18next';

import { createDataId } from '../../../../common/utils/dataId';
import { Button, ButtonType } from '../../../Buttons/Button';
import Checkbox from '../../../Checkbox/Checkbox';
import { CompanyNotificationsSetting, CompanyWithNotificationsSetting } from '../../UserProfile';
import { useDispatch, useSelector } from 'react-redux';
import { loadMoreCompaniesNotificationsSettings, resetUserCompaniesNotificationsPagingOptions } from '../../../../common/user/UserActions';
import { getUserCompaniesTotalCount } from '../../../../common/user/UserSelectors';

interface CompaniesNotificationsProps {
    dataId: string;
    isLoading: boolean;
    userCompanies: CompanyWithNotificationsSetting[];
    onSave: (s: CompanyNotificationsSetting[]) => void;
}

const CompaniesNotifications = ({ dataId, isLoading, onSave, userCompanies, t }: CompaniesNotificationsProps & WithTranslation) => {
    const [notificationsSettings, setNotificationsSetting] = useState<CompanyNotificationsSetting[]>([]);
    const totalUserCompanies = useSelector(getUserCompaniesTotalCount);

    const dispatch = useDispatch();

    const isShowMoreButton = totalUserCompanies > userCompanies.length;

    const currentNotificationsSettings = useMemo((): CompanyNotificationsSetting[] => {
        const currentSettings: CompanyNotificationsSetting[] = [];
        for (const userCompany of userCompanies) {
            if (userCompany.notificationsOn) {
                currentSettings.push({ CompanyGuid: userCompany.CompanyGuid, notificationsOn: userCompany.notificationsOn });
            }
        }
        return currentSettings;
    }, [userCompanies]);

    const isPartiallyChecked = (): boolean => {
        const numberNotificationsOn = notificationsSettings.length;
        return numberNotificationsOn !== 0 && numberNotificationsOn < userCompanies.length;
    };

    const isChecked = (id: string): boolean => {
        return !!notificationsSettings.find((s) => s.CompanyGuid === id);
    };

    const onAllChange = () => {
        if (notificationsSettings.length !== userCompanies.length) {
            // check all
            const settings: CompanyNotificationsSetting[] = [];
            userCompanies.map((c) => {
                settings.push({ CompanyGuid: c.CompanyGuid, notificationsOn: true });
            });
            setNotificationsSetting(settings);
        } else {
            // uncheck all
            setNotificationsSetting([]);
        }
    };

    const checkCompany = (id: string) => {
        const setting: CompanyNotificationsSetting = notificationsSettings.find((s) => s.CompanyGuid === id);

        if (setting) {
            const newSettings = notificationsSettings.filter((s) => s.CompanyGuid !== setting.CompanyGuid);
            setNotificationsSetting(newSettings);
        } else {
            setNotificationsSetting([...notificationsSettings, { CompanyGuid: id, notificationsOn: true }]);
        }
    };

    const handleClickLoadMore = () => {
        dispatch(loadMoreCompaniesNotificationsSettings());
    };

    useEffect(() => {
        if (!isEqual(notificationsSettings, currentNotificationsSettings)) {
            setNotificationsSetting(currentNotificationsSettings);
        }
    }, [currentNotificationsSettings]);

    useEffect(() => {
        return () => {
            dispatch(resetUserCompaniesNotificationsPagingOptions());
        };
    }, []);
    return (
        <section className="checkbox-list" data-id={createDataId(dataId, 'notifications')}>
            <ul data-id={createDataId(dataId, 'notifications', 'companies-list')}>
                <li className="all-items-checkbox">
                    <Checkbox
                        name="all-companies"
                        label={t('views.global.UserProfileModal.companies')}
                        value={notificationsSettings.length === userCompanies.length || isPartiallyChecked()}
                        partiallyChecked={isPartiallyChecked()}
                        data-id={createDataId(dataId, 'checkbox.all-companies')}
                        onChange={onAllChange}
                    />
                </li>
                {userCompanies.map((c) => {
                    return (
                        <li key={c.CompanyGuid} data-id={createDataId(dataId, 'companies-list-item.company', c.CompanyGuid)}>
                            <Checkbox
                                name={c.CompanyName}
                                label={c.CompanyName}
                                value={isChecked(c.CompanyGuid)}
                                onChange={() => checkCompany(c.CompanyGuid)}
                                data-id={createDataId(dataId, 'checkbox.company', c.CompanyGuid)}
                            />
                        </li>
                    );
                })}
            </ul>
            {isShowMoreButton && (
                <Button loading={isLoading} onClick={handleClickLoadMore} data-id={createDataId(dataId, 'button.loadMore')} buttonType={ButtonType.SECONDARY}>
                    {t('component.changeCompany.loadMore')}
                </Button>
            )}

            <div className="user-profile__footer" data-id={createDataId(dataId, 'footer')}>
                <Button loading={isLoading} onClick={() => onSave(notificationsSettings)} data-id={createDataId(dataId, 'button.save')}>
                    {t('views.global.UserProfileModal.Save')}
                </Button>
            </div>
        </section>
    );
};

export default withTranslation()(CompaniesNotifications);
