import { isNull, round, set, uniqueId } from 'lodash-es';
import Big from 'big.js';

import api from '../../services/ApiServices';
import { PurchaseOrderFileAttachmentDTO, PurchaseOrdersDTO, SupplierDTO } from '../../services/types/ApiTypes';
import { PurchaseOrdersAddViewEmptyRowFields, rowFieldNames } from '../purchase-orders-add/purchaseOrderAddViewFields';
import { formatMoneyAndCurrency, formatRemoveThousandsSeparator, formatThousandsWithDecimal } from '../../common/utils/formatters';
import { PurchaseOrderNewRowFields, PurchaseOrdersNewViewFields } from './PurchaseOrdersNewTypes';
import { FileType } from './components/Attachments/components/FileInput/FileInputTypes';

export const inputPlaceholder = '–';

export const dropZoneId = 'poDropZone';

const idPrefix = 'ROW_KEY_';

export const getUniqueId = () => uniqueId(idPrefix);

export const dataId = 'purchase-order-new';

export const dataIdAttachments = 'purchase-order-attachments';

export const getInitialRowObject = (): PurchaseOrdersAddViewEmptyRowFields => ({
    Description: '',
    Quantity: null,
    Unit: '',
    UnitPrice: null,
    SumWithoutVat: null,
    Vat: null,
    VatAmount: null,
    Total: null,
    Key: getUniqueId(),
});

export function getInitialValue() {
    return [getInitialRowObject()];
}

const isNumber = (value: number) => typeof value === 'number' && !isNaN(value);

const parseRows = (rows: PurchaseOrderNewRowFields[]) => {
    return rows
        .map((row) => {
            delete row['Key'];
            return row;
        })
        .filter((e) => Object.keys(e).some((x) => e[x]));
};

const parseBytesToBase64 = (bytes: Uint8Array): string => {
    let binaryString = '';
    for (let i = 0; i < bytes.byteLength; i++) {
        binaryString += String.fromCharCode(bytes[i]);
    }

    return window.btoa(binaryString);
};

const parseFiles = (files: FileType[]): PurchaseOrderFileAttachmentDTO[] => {
    const newFiles = files.map((e) => {
        return {
            PurchaseOrderId: null,
            FileName: e.fileName,
            Extension: null,
            PathToFile: null,
            Base64Content: parseBytesToBase64(e.bytes),
            FileUrl: null,
            ModifiedById: null,
            UserFullName: null,
            UploadedDateTime: new Date().toISOString(),
            Id: null,
            IsNew: null,
        };
    });

    return newFiles;
};

export const createPurchaseOrdersDTO = async (values: PurchaseOrdersNewViewFields): Promise<PurchaseOrdersDTO> => ({
    DecisionDate: null,
    Description: values.Description,
    OrderNumber: null,
    OrderType: values.Type,
    SupplierId: values?.Supplier?.value?.Id ? values.Supplier.value.Id : null,
    Currency: values.Currency.value,
    IsManualFulfilment: false,
    RemainingSum: 0,
    PurchaseOrdersRows: parseRows(values.Rows),
    DocumentFiles: parseFiles(values.DocumnetFiles),
});

export const getSuppliersByName = async (suppName?: string): Promise<SupplierDTO[]> => {
    if (suppName) {
        const response = await api.suppliers.getSuppliersByName(suppName);
        return response.data;
    }
    const response = await api.suppliers.getSuppliers();
    return response.data.Items;
};

export const getSumByField = (fields: PurchaseOrderNewRowFields[], fieldName: keyof PurchaseOrderNewRowFields): string => {
    // using Big.js to avoid floating point arithmetic issues
    let result = new Big(0);
    for (const field of fields) {
        if (field[fieldName]) {
            const normalizedValue = Number(formatRemoveThousandsSeparator(field[fieldName]));
            const value = !isNaN(normalizedValue) ? normalizedValue : 0;
            result = result.plus(value);
        }
    }
    if (result.eq(0)) {
        return '0';
    }
    return formatMoneyAndCurrency(Number(result), ' ');
};

export function roundBig(value: Big, dp = 2) {
    return round(Number(value), dp);
}

export type UpdateRowTotalValueParamsValues = {
    sumWithoutVat: number;
    vatAmount: number;
};
export const updateRowTotalValue = (rowValues: PurchaseOrderNewRowFields, current: UpdateRowTotalValueParamsValues, newValues?: Partial<UpdateRowTotalValueParamsValues>) => {
    if ((isNumber(current.sumWithoutVat) || isNumber(newValues.sumWithoutVat)) && (isNumber(current.vatAmount) || isNumber(newValues.vatAmount))) {
        const vatAmount = newValues?.vatAmount || current?.vatAmount;
        const netValue = newValues?.sumWithoutVat || current?.sumWithoutVat;
        const newTotalValue = roundBig(Big(netValue).add(vatAmount));
        setFieldValue(rowFieldNames.Total, formatThousandsWithDecimal(newTotalValue), rowValues);
    }
};

export const rowValueCalculationFns = {
    [rowFieldNames.Quantity]: (rowValues: PurchaseOrderNewRowFields) => {
        const quantityValue = Number(formatRemoveThousandsSeparator(rowValues.Quantity));
        const sumWithoutVat = Number(formatRemoveThousandsSeparator(rowValues.SumWithoutVat));
        const priceValue = Number(formatRemoveThousandsSeparator(rowValues.UnitPrice));
        const vatValue = Number(rowValues.Vat);
        const vatAmountValue = Number(formatRemoveThousandsSeparator(rowValues.VatAmount));

        if (!isNaN(quantityValue) && !isNaN(sumWithoutVat) && !priceValue) {
            const newFieldValue = roundBig(Big(sumWithoutVat).div(quantityValue), 4);
            setFieldValue(rowFieldNames.UnitPrice, formatThousandsWithDecimal(newFieldValue), rowValues);
        }

        if (isNumber(quantityValue) && isNumber(priceValue)) {
            const newSumWithoutVatValue = roundBig(Big(priceValue).times(quantityValue));
            const newVatAmountValue = roundBig(
                Big(newSumWithoutVatValue)
                    .times(vatValue)
                    .div(100),
            );

            setFieldValue(rowFieldNames.SumWithoutVat, formatThousandsWithDecimal(newSumWithoutVatValue), rowValues);
            if (!isNull(rowValues.Vat)) {
                setFieldValue(rowFieldNames.VatAmount, formatThousandsWithDecimal(newVatAmountValue), rowValues);
            }

            updateRowTotalValue(rowValues, { sumWithoutVat, vatAmount: vatAmountValue }, { sumWithoutVat: newSumWithoutVatValue, vatAmount: newVatAmountValue });
        }
    },

    [rowFieldNames.UnitPrice]: (rowValues: PurchaseOrderNewRowFields) => {
        const priceValue = Number(formatRemoveThousandsSeparator(rowValues[rowFieldNames.UnitPrice]));
        const quantityValue = Number(formatRemoveThousandsSeparator(rowValues.Quantity));
        const sumWithoutVatValue = Number(formatRemoveThousandsSeparator(rowValues.SumWithoutVat));
        if (isNumber(priceValue) && isNumber(quantityValue)) {
            const newSumWithoutVatValue = roundBig(Big(quantityValue).times(priceValue));
            const vatAmountValue = Number(formatRemoveThousandsSeparator(rowValues.VatAmount)) || 0;
            const newTotalValue = roundBig(Big(newSumWithoutVatValue).add(vatAmountValue));
            setFieldValue(rowFieldNames.SumWithoutVat, formatThousandsWithDecimal(newSumWithoutVatValue), rowValues);
            setFieldValue(rowFieldNames.Total, formatThousandsWithDecimal(newTotalValue), rowValues);
        }

        if (isNumber(priceValue) && isNumber(sumWithoutVatValue) && !quantityValue) {
            const newNetValue = roundBig(Big(sumWithoutVatValue).div(priceValue), 4);
            setFieldValue(rowFieldNames.Quantity, formatThousandsWithDecimal(newNetValue), rowValues);
        }
    },

    [rowFieldNames.SumWithoutVat]: (rowValues: PurchaseOrderNewRowFields) => {
        const sumWithoutVatValue = Number(formatRemoveThousandsSeparator(rowValues[rowFieldNames.SumWithoutVat]));
        const vatAmountValue = Number(formatRemoveThousandsSeparator(rowValues.VatAmount || 0));
        const quantityValue = Number(formatRemoveThousandsSeparator(rowValues.Quantity));
        const unitPriceValue = Number(formatRemoveThousandsSeparator(rowValues.UnitPrice));
        const vatValue = Number(formatRemoveThousandsSeparator(rowValues.Vat));

        if (isNumber(sumWithoutVatValue) && isNumber(quantityValue) && isNumber(unitPriceValue)) {
            const newQuantityValue = roundBig(Big(sumWithoutVatValue).div(unitPriceValue), 4);
            setFieldValue(rowFieldNames.Quantity, formatThousandsWithDecimal(newQuantityValue), rowValues);
        }

        if (isNumber(sumWithoutVatValue) && isNumber(quantityValue) && !unitPriceValue) {
            const newUnitPriceValue = roundBig(Big(sumWithoutVatValue).div(quantityValue), 4);
            setFieldValue(rowFieldNames.UnitPrice, formatThousandsWithDecimal(newUnitPriceValue), rowValues);
        }

        if (isNumber(sumWithoutVatValue) && !vatValue && isNumber(vatAmountValue)) {
            const newVatValue = roundBig(Big(vatAmountValue).div(sumWithoutVatValue)) * 100;
            setFieldValue(rowFieldNames.Vat, formatThousandsWithDecimal(newVatValue), rowValues);
        }

        if (isNumber(sumWithoutVatValue) && isNumber(vatValue)) {
            const newVatAmountValue = roundBig(
                Big(sumWithoutVatValue)
                    .times(vatValue)
                    .div(100),
            );
            const newTotalValue = roundBig(Big(sumWithoutVatValue).add(newVatAmountValue));
            setFieldValue(rowFieldNames.VatAmount, formatThousandsWithDecimal(newVatAmountValue), rowValues);
            setFieldValue(rowFieldNames.Total, formatThousandsWithDecimal(newTotalValue), rowValues);
        }
        if (isNumber(sumWithoutVatValue) && !isNumber(vatValue)) {
            setFieldValue(rowFieldNames.Total, formatThousandsWithDecimal(sumWithoutVatValue), rowValues);
        }
    },
    [rowFieldNames.Vat]: (rowValues: PurchaseOrderNewRowFields) => {
        const vatValue = Number(formatRemoveThousandsSeparator(rowValues[rowFieldNames.Vat]));
        const sumWithoutVatValue = Number(formatRemoveThousandsSeparator(rowValues.SumWithoutVat));
        if (isNumber(vatValue) && sumWithoutVatValue) {
            const newVatAmountValue = roundBig(
                Big(sumWithoutVatValue)
                    .times(vatValue)
                    .div(100),
                2,
            );
            setFieldValue(rowFieldNames.VatAmount, formatThousandsWithDecimal(newVatAmountValue), rowValues);
            updateRowTotalValue(rowValues, { sumWithoutVat: sumWithoutVatValue, vatAmount: newVatAmountValue });
        }
    },

    [rowFieldNames.VatAmount]: (rowValues: PurchaseOrderNewRowFields) => {
        const vatAmountValue = Number(formatRemoveThousandsSeparator(rowValues[rowFieldNames.VatAmount]));
        const totalValue = Number(formatRemoveThousandsSeparator(rowValues.Total));
        const sumWithoutVatValue = Number(formatRemoveThousandsSeparator(rowValues.SumWithoutVat));

        if (isNumber(vatAmountValue) && sumWithoutVatValue) {
            const newVatAmountValue = roundBig(Big(vatAmountValue).div(sumWithoutVatValue)) * 100;
            setFieldValue(rowFieldNames.Vat, formatThousandsWithDecimal(newVatAmountValue), rowValues);
        }

        if (isNumber(vatAmountValue) && totalValue && !sumWithoutVatValue) {
            const newTotalValue = roundBig(Big(totalValue).minus(vatAmountValue));
            setFieldValue(rowFieldNames.SumWithoutVat, formatThousandsWithDecimal(newTotalValue), rowValues);
        }

        if (isNumber(vatAmountValue)) {
            const newSumWithoutVatValue = roundBig(Big(sumWithoutVatValue || 0).add(vatAmountValue));
            setFieldValue(rowFieldNames.Total, formatThousandsWithDecimal(newSumWithoutVatValue), rowValues);
        }
    },

    [rowFieldNames.Total]: (rowValues: PurchaseOrderNewRowFields) => {
        const vatAmountValue = Number(formatRemoveThousandsSeparator(rowValues[rowFieldNames.VatAmount])) || 0;
        const totalValue = Number(formatRemoveThousandsSeparator(rowValues.Total)) || 0;
        const newSumWithoutVatValue = roundBig(Big(totalValue).minus(vatAmountValue));
        setFieldValue(rowFieldNames.SumWithoutVat, formatThousandsWithDecimal(newSumWithoutVatValue), rowValues);
    },
};

function setFieldValue(fieldName: string, newFieldValue: any, rowValues: PurchaseOrderNewRowFields) {
    // FIXME: fix update flow and remove this function at all.
    set(rowValues, fieldName, newFieldValue);
}

export const fieldNamesSource: PurchaseOrdersNewViewFields = {
    Description: null,
    DocumnetFiles: [],
    Supplier: null,
    Issued: null,
    Type: null,
    Rows: null,
    Id: null,
    Currency: null,
    CreatedBy: null,
};

export const normalizePORowsToSubmit = (rows: PurchaseOrderNewRowFields[]) => {
    return rows.map(
        (row): PurchaseOrderNewRowFields => {
            return {
                ...row,
                Quantity: Number(formatRemoveThousandsSeparator(row.Quantity)),
                SumWithoutVat: Number(formatRemoveThousandsSeparator(row.SumWithoutVat)),
                UnitPrice: Number(formatRemoveThousandsSeparator(row.UnitPrice)),
                Total: Number(formatRemoveThousandsSeparator(row.Total)),
                VatAmount: Number(formatRemoveThousandsSeparator(row.VatAmount)),
                Vat: Number(row.Vat),
            };
        },
    );
};
