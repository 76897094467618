import { isEmpty } from 'lodash-es';
import { createAction } from 'redux-actions';
import { saveAs } from 'file-saver';

import { ListViewUserConfig } from '../../../common/user/userSettingUtil';
import { BaseSearchWithTableFilters, PagedListContainerOf, Restriction, SearchType, SortDirection, UserSettingName } from '../../../services/types/ApiTypes';
import { loadableDataActions, loadableDataActionsWithRequest } from '../../../common/utils/LoadableData';
import { BackOfficeUserDTO } from '../../../services/types/BoApiTypes';
import { TableFilter } from '../../../components/Table/components/filter/TableFilters';
import { DispatchThunk } from '../../../storeConfig';
import { GlobalState } from '../../../rootReducer';
import { validateAndFixPagingOptions, validateAndFixSortItems } from '../../../common/utils/baseSearchHelpers';
import api from '../../../services/ApiServices';
import i18n from '../../../i18n';
import { updateBoUserSettingsAction } from '../../../common/middlewares/userSettings';
import { createRequest } from './boUserListHelpers';
import { getBoCurrentUser } from '../common/BoUserActions';
import { getViewBoUserSearchParams } from '../common/boUserSettingUtil';
import { notify } from '../../../common/utils/notify';
import { formatDate } from '../../../common/utils/formatters';

const listViewConfig: ListViewUserConfig = {
    sortDir: UserSettingName.BACK_OFFICE_USERS_SORT_DIRECTION,
    sortCol: UserSettingName.BACK_OFFICE_USERS_SORT_COLUMN,
    pageSize: UserSettingName.BACK_OFFICE_USERS_PAGE_SIZE,
    filters: UserSettingName.BACK_OFFICE_USERS_FILTERS,
};

const listViewConfigAllUsers: ListViewUserConfig = {
    sortDir: UserSettingName.BACK_OFFICE_ALL_USERS_SORT_DIRECTION,
    sortCol: UserSettingName.BACK_OFFICE_ALL_USERS_SORT_COLUMN,
    pageSize: UserSettingName.BACK_OFFICE_ALL_USERS_PAGE_SIZE,
    filters: UserSettingName.BACK_OFFICE_ALL_USERS_FILTERS,
};

const getListViewConfig = (isAllUsersView: boolean) => (isAllUsersView ? listViewConfigAllUsers : listViewConfig);

export type BoUserListSearchParams = BaseSearchWithTableFilters<Array<TableFilter<any>>, BackOfficeUserDTO>;
export const DEFAULT_RESTRICTION = 'GeneralSearch';
export const ALL_USERS_DEFAULT_RESTRICTION = 'GeneralSearchAllUsers';

const ns = 'back-office-user-list/';
export const getBoUserListLoadable = loadableDataActionsWithRequest<BoUserListSearchParams, PagedListContainerOf<BackOfficeUserDTO>>(`${ns}ALL_BO_USERS`);
export const getAllBoUserListLoadable = loadableDataActionsWithRequest<BoUserListSearchParams, PagedListContainerOf<BackOfficeUserDTO>>(`${ns}BO_USERS`);
export const addOrEditBoUserAction = loadableDataActions<BackOfficeUserDTO, BackOfficeUserDTO>(`${ns}ADD_OR_EDIT_BO_USER`);
export const deleteBoUserAction = loadableDataActions<string, boolean>(`${ns}DELETE_BO_USER`);
export const setUserTouchedBoRoleFilter = createAction<boolean>(`${ns}SET_USER_TOUCHED_BACK_OFFICE_ROLE_FILTER`);
export const setIsAllUsersView = createAction<boolean>(`${ns}SET_IS_ALL_USERS_VIEW`); // BO Users view and user editting modal depend on this
export const exportBoUsersToCsvActions = loadableDataActions(`${ns}EXPORT_BO_USERS_TO_CSV`);
export const exportBoUsersToXlsActions = loadableDataActions(`${ns}EXPORT_BO_USERS_TO_XLS`);

export const getBoUserList = (searchParams?: BoUserListSearchParams, isAllUsersView?: boolean) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        if (isEmpty(state.boUser.boCurrentUserLoadable.payload)) {
            await dispatch(getBoCurrentUser());
        }
        const {
            boUserManagement,
            boUser: {
                boCurrentUserLoadable: { payload: currentUser },
            },
        } = getState();

        const stateSearchParams = isAllUsersView ? boUserManagement.searchParamsAllUsers : boUserManagement.searchParams;
        searchParams = searchParams || stateSearchParams || createRequest('', 1, 15, DEFAULT_RESTRICTION, isAllUsersView);
        const listViewConfig = getListViewConfig(isAllUsersView);
        const viewSearchParams = getViewBoUserSearchParams(searchParams, listViewConfig, currentUser);

        const actions = isAllUsersView ? getAllBoUserListLoadable : getBoUserListLoadable;

        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, DEFAULT_RESTRICTION);

        const apiSearchParams = {
            ...searchParams,
            ...viewSearchParams,
        };

        delete apiSearchParams.filters;
        let response;
        try {
            dispatch(actions.request(searchParams));
            response = await api.boUsers.getUsers(searchParams);
            dispatch(
                actions.success({
                    result: response.data,
                    request: searchParams,
                }),
            );
        } catch (e) {
            dispatch(
                actions.error({
                    request: searchParams,
                    result: e,
                }),
            );
        }
    };
};

export const setPagingOptions = (page: number, pageSize?: number, isAllUsersView?: boolean) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const { boUserManagement } = getState();
        const params = isAllUsersView ? boUserManagement.searchParamsAllUsers : boUserManagement.searchParams;
        const paging = params?.PagingOptions;
        const searchParams: BoUserListSearchParams = {
            ...params,
            PagingOptions: {
                Page: !pageSize || (pageSize && pageSize === paging.Count) ? page : 1,
                Count: pageSize && pageSize !== paging.Count ? pageSize : paging.Count,
            },
        };

        const listViewConfig = getListViewConfig(isAllUsersView);

        await dispatch(
            updateBoUserSettingsAction({
                listViewConfig,
                searchParams,
            }),
        );
        dispatch(
            getBoUserList(
                {
                    ...searchParams,
                    PagingOptions: {
                        Page: page || searchParams.PagingOptions.Page,
                        Count: pageSize || searchParams.PagingOptions.Count,
                    },
                },
                isAllUsersView,
            ),
        );
    };
};

export const searchBoUsers = (searchString: string, isAllUsersView: boolean) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const { boUserManagement } = getState();
            let searchParams: BoUserListSearchParams = isAllUsersView ? boUserManagement.searchParamsAllUsers : boUserManagement.searchParams;
            const paging = searchParams.PagingOptions;

            const searchRestrictionField: string = isAllUsersView ? ALL_USERS_DEFAULT_RESTRICTION : DEFAULT_RESTRICTION;
            const otherRestrictions = searchParams.Restrictions.filter((r: Restriction) => searchRestrictionField !== r.Field);
            let restrictions: Restriction[] = [];
            if (searchString) {
                restrictions.push({
                    Field: searchRestrictionField,
                    Value: searchString,
                    Values: null,
                    FieldSearchType: SearchType.NotSelected,
                });
            }
            restrictions = [...restrictions, ...otherRestrictions];
            searchParams = {
                ...searchParams,
                PagingOptions: {
                    ...paging,
                    Page: 1, //reset to first page when searching
                },
                Restrictions: [...restrictions],
            };
            dispatch(getBoUserList(searchParams, isAllUsersView));
        } catch (e) {
            console.error(e);
        }
    };
};

export const sortBoUsers = (column: string, isAllUsersView: boolean) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            const { boUserManagement } = getState();
            const params = isAllUsersView ? boUserManagement.searchParamsAllUsers : boUserManagement.searchParams;
            const sorting = params?.SortItems[0];

            const searchParams: BoUserListSearchParams = {
                ...params,
                SortItems: [
                    {
                        SortColumn: column,
                        SortDirection: sorting && sorting.SortColumn === column ? (sorting && sorting.SortDirection === SortDirection.Asc ? SortDirection.Desc : SortDirection.Asc) : SortDirection.Asc,
                    },
                ],
            };

            const listViewConfig = getListViewConfig(isAllUsersView);

            await dispatch(
                updateBoUserSettingsAction({
                    listViewConfig,
                    searchParams,
                }),
            );
            dispatch(getBoUserList(searchParams, isAllUsersView));
        } catch (e) {
            console.error(e);
        }
    };
};

export const filterBoUsers = (restriction?: Restriction, filter?: TableFilter<any>) => {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        const isAllUsersView = state.boUserManagement.isAllUsersView;
        const currentSearchParams = isAllUsersView ? state.boUserManagement.searchParamsAllUsers : state.boUserManagement.searchParams;
        const paging = currentSearchParams.PagingOptions;
        // keep the old restrictions when we filter
        const restrictions = currentSearchParams.Restrictions.filter((r, index) => {
            if (index === 0) {
                return r;
            }
            if (r.Field === restriction.Field) {
                if (isEmpty(restriction.Value) && isEmpty(restriction.Values)) {
                    return false;
                }
                return !!restriction.Values || !!restriction.Value;
            }
            return !!r.Value || !!r.Values;
        }).map((r) => {
            if (r.Field === restriction.Field) {
                return restriction;
            }
            return r;
        });
        if (!!restriction && !restrictions.includes(restriction) && ((typeof restriction.Value === 'number' ? restriction.Value !== 0 : !isEmpty(restriction.Value)) || !isEmpty(restriction.Values))) {
            restrictions.push(restriction);
        }

        const searchParams: BoUserListSearchParams = {
            ...currentSearchParams,
            PagingOptions: {
                ...paging,
                Page: 1, // reset to first page when searching
            },
            Restrictions: restrictions,
            filters: {
                ...currentSearchParams.filters,
                [restriction.Field]: filter,
            },
        };
        const resellersRestriction = restrictions.find((r) => r.Field === 'ResellerGuid');
        if (resellersRestriction) {
            const { Value } = resellersRestriction;

            if (resellersRestriction && !Value && !searchParams.filters.ResellerGuid?.values?.length) {
                searchParams.Restrictions = searchParams.Restrictions.filter((r) => r.Field !== 'ResellerGuid');
            }
        }
        const listViewConfig = getListViewConfig(isAllUsersView);
        await dispatch(
            updateBoUserSettingsAction({
                searchParams,
                listViewConfig,
            }),
        );
        dispatch(setUserTouchedBoRoleFilter(!!filter));
        dispatch(getBoUserList(searchParams, isAllUsersView));
    };
};

export const addOrEditBoUser = (user: BackOfficeUserDTO) => {
    return async (dispatch: DispatchThunk) => {
        let response;
        try {
            dispatch(addOrEditBoUserAction.request(user));
            response = await api.boUsers.saveUser(user);
            dispatch(addOrEditBoUserAction.success(response.data));
        } catch (e) {
            console.error(e);
        }
    };
};

export function deleteBoUser(userGuid: string) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        try {
            dispatch(deleteBoUserAction.request(userGuid));
            const response = await api.boUsers.deleteUser(userGuid);
            dispatch(deleteBoUserAction.success(response.data));
            dispatch(getBoUserList(getState().boUserManagement.searchParams));
        } catch (e) {
            console.error(e);
        }
    };
}

export function exportBoUsersToCsv(isAllUsers?: boolean) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        if (isEmpty(state.boUser.boCurrentUserLoadable.payload)) {
            await dispatch(getBoCurrentUser());
        }
        const {
            boUserManagement,
            boUser: {
                boCurrentUserLoadable: { payload: currentUser },
            },
        } = getState();

        dispatch(exportBoUsersToCsvActions.request(undefined));

        let searchParams = isAllUsers ? boUserManagement.searchParamsAllUsers : boUserManagement.searchParams;

        searchParams = searchParams || createRequest('', 1, 15, DEFAULT_RESTRICTION, isAllUsers);
        const listViewConfig = getListViewConfig(isAllUsers);
        const viewSearchParams = getViewBoUserSearchParams(searchParams, listViewConfig, currentUser);

        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, DEFAULT_RESTRICTION);

        const apiSearchParams = {
            ...searchParams,
            ...viewSearchParams,
        };

        try {
            const response = await api.boUsers.exportUsersToCsv(apiSearchParams);
            const fileName = `Bo_Users_${formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.csv`;
            const file = new Blob([response.data], { type: 'text/csv;charset=utf-8;' });
            saveAs(file, fileName);
            dispatch(exportBoUsersToCsvActions.success(fileName));
        } catch (e) {
            console.error(e);
            dispatch(exportBoUsersToCsvActions.error(e));
        }
    };
}

export function exportBoUsersToXls(isAllUsers?: boolean) {
    return async (dispatch: DispatchThunk, getState: () => GlobalState) => {
        const state = getState();
        if (isEmpty(state.boUser.boCurrentUserLoadable.payload)) {
            await dispatch(getBoCurrentUser());
        }
        const {
            boUserManagement,
            boUser: {
                boCurrentUserLoadable: { payload: currentUser },
            },
        } = getState();

        dispatch(exportBoUsersToXlsActions.request(undefined));

        let searchParams = isAllUsers ? boUserManagement.searchParamsAllUsers : boUserManagement.searchParams;

        searchParams = searchParams || createRequest('', 1, 15, DEFAULT_RESTRICTION, isAllUsers);
        const listViewConfig = getListViewConfig(isAllUsers);
        const viewSearchParams = getViewBoUserSearchParams(searchParams, listViewConfig, currentUser);

        viewSearchParams.PagingOptions = validateAndFixPagingOptions(viewSearchParams.PagingOptions);
        viewSearchParams.SortItems = validateAndFixSortItems(viewSearchParams.SortItems, DEFAULT_RESTRICTION);

        const apiSearchParams = {
            ...searchParams,
            ...viewSearchParams,
        };
        try {
            const response = await api.boUsers.exportUsersToXls(apiSearchParams);
            const fileName = `Bo_Users_${formatDate(new Date(), 'yyyy-MM-dd_HH-mm-ss')}.xlsx`;
            const file = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8' });
            saveAs(file, fileName);
            dispatch(exportBoUsersToXlsActions.success(fileName));
        } catch (e) {
            console.error(e);
            dispatch(exportBoUsersToXlsActions.error(e));
        }
    };
}

export async function activateUserEmail(userGuid: string, name: string) {
    try {
        const response = await api.boUsers.activateEmail(userGuid);
        if (response.data.Success) {
            notify.success(
                i18n.t('view.backOffice.allUsers.activateEmailForUser.Successfully', {
                    userName: name,
                }),
            );
        } else {
            notify.error(i18n.t('interceptorsFactory.ErrorWhileProcessingData'), i18n.t('interceptorsFactory.Error'));
        }
    } catch (e) {
        console.error(e);
        notify.error(i18n.t('interceptorsFactory.ErrorWhileProcessingData'), i18n.t('interceptorsFactory.Error'));
    }
}
