import React from 'react';
import classNames from 'classnames';
import { debounce, isEmpty, isEqual } from 'lodash-es';
import { WithTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import { Dispatchable0, Dispatchable1, Dispatchable2 } from 'redux-dispatchers';

import { eventTrack } from '../../common/analytics/gtm';
import { DEFAULT_CURRENCY_PLACEHOLDER } from '../../common/constants/appConstants';
import { isAuthorized, Role } from '../../common/user/userPermissionUtil';
import { createDataId } from '../../common/utils/dataId';
import { DefaultTimeRange, getRangeEnumFromDates } from '../../common/utils/datetime';
import { formatDate, formatMoneyAndCurrency } from '../../common/utils/formatters';
import { LoadableData } from '../../common/utils/LoadableData';
import AccessLevel from '../../components/AccessLevel/AccessLevel';
import { BaseStatefulComponent } from '../../components/BaseStatefulComponent';
import { Button, ButtonIconPlacement, ButtonType } from '../../components/Buttons/Button';
import { DateRangeValues } from '../../components/CalendarDateRangeModal/CalendarDateRangeModal';
import { ContentBlock } from '../../components/ContentBlock/ContentBlock';
import { ContentBlockBody } from '../../components/ContentBlock/ContentBlockBody';
import { ContentBlockFooter, ContentBlockFooterType } from '../../components/ContentBlock/ContentBlockFooter';
import { ContentBlockHeader } from '../../components/ContentBlock/ContentBlockHeader';
import ContentBlockHeading, { ContentBlockHeadingType } from '../../components/ContentBlock/ContentBlockHeading';
import DateRangeFilter from '../../components/ContentBlock/DateRangeFilter';
import EmptySearchResults from '../../components/EmptySearchResults/EmptySearchResults';
import Icon, { ICONS } from '../../components/Icon/Icon';
import { MainPage, MainPageType } from '../../components/MainPage/MainPage';
import Pager from '../../components/Pager/Pager';
import Scrollbars from '../../components/Scrollbars/Scrollbars';
import { TableFilter } from '../../components/Table/components/filter/TableFilters';
import { TableSettingsModal } from '../../components/Table/components/TableColumnsConfigurable/components/TableColumnsSettingsModal/TableSettingsModalView';
import { TableRowConfigurable } from '../../components/Table/components/TableColumnsConfigurable/components/TableRowConfigurable/TableRowConfigurable';
import {
    TableColumnsConfigurableContextProps,
    TableColumnsConfigurableItem,
    TableColumnsConfigurableProvider,
} from '../../components/Table/components/TableColumnsConfigurable/TableColumnsConfigurableContext';
import Table from '../../components/Table/Table';
import TableBody from '../../components/Table/TableBody';
import TableCell, { ResponsiveCellType } from '../../components/Table/TableCell';
import TableHead from '../../components/Table/TableHead';
import { DataTableHeader } from '../../components/Table/TableHeader';
import TableRow from '../../components/Table/TableRow';
import { TagSelectItem, TagSelectRepositionerContext } from '../../components/TagSelect/TagSelect';
import { TextInput, TextInputType } from '../../components/TextInput/TextInput';
import Tooltip from '../../components/Tooltip/Tooltip';
import { Typography } from '../../components/Ui/Typography';
import { User } from '../../services/ApiClient';
import { BaseSearch, GroupMemberCommonDTO, PurchaseOrderExtraStatus, PurchaseOrderStatus, Restriction, SearchType, UserSettingName } from '../../services/types/ApiTypes';
import PurchaseOrdersViewItemMenu from './components/ItemMenu/PurchaseOrdersViewItemMenu';
import { FilterItemSelect, FilterItemSelectProps } from './components/PoFilters/components/FilterIitemSelect/FilterItemSelect';
import PoFulfilmentView from './components/PoFulfilment/PoFulfilmentView';
import PoStatusChip from './components/Status/PoStatusLabel';
import {
    DATE_RESTRICTION,
    DEFAULT_RESTRICTION,
    defaultPOColumns,
    getApprovers,
    getDecisionDate,
    getRangeLabel,
    getStatusItemsArray,
    getSupplierList,
    getTypeKey,
    MIN_TABLE_COLUMN_WIDTH,
    parseFilters,
    parseRestrictions,
    PO_COLUMN_ID,
    translationsKeys,
} from './PurchaseOrdersViewHelper';
import { PurchaseOrdersViewState } from './PurchaseOrdersViewReducer';

import './PurchaseOrdersView.scss';

export interface Props extends Pick<PurchaseOrdersViewState, 'purchaseOrdersLoadable' | 'deletePurchaseOrderLoadable' | 'userTouchedStatusFilter' | 'searchParams'> {
    userData: User;
    groupMember: LoadableData<GroupMemberCommonDTO>;
    sideMenuIsOpen: boolean;
}

export interface DispatchProps {
    filterPurchaseOrders: Dispatchable2<Restriction, Omit<TableFilter<any>, 'onSelectChangeCallback' | 'tagSelectType'>>;
    setUserTouchedPOListStatusFilter: Dispatchable1<boolean>;
    setPurchaseOrdersPagingOptions: Dispatchable2<number, number>;
    sortPurchaseOrders: Dispatchable1<string>;
    searchPurchaseOrders: Dispatchable1<string> | Dispatchable2<string, boolean>;
    getPurchaseOrdersList: Dispatchable1<BaseSearch>;
    deletePurchaseOrder: Dispatchable1<number>;
    duplicatePurchaseOrder: Dispatchable1<string>;
    exportPurchaseOrdersToXLSX: Dispatchable0;
    exportPurchaseOrderToPdf: Dispatchable2<string, string>;
    setTableColumnsConfigurable: Dispatchable1<TableColumnsConfigurableItem[]>;
}

interface RouteParams {}

export type PurchaseOrdersViewProps = Props & DispatchProps & WithTranslation & RouteComponentProps<RouteParams>;

export interface State {
    appliedFilters: Array<TableFilter<any>>;
    defaultTimeRangeValue: DefaultTimeRange | null;
    scrollbarRerenderTrigger: number;
    searchInputValue: string;
    tableHeight: number;
}

class PurchaseOrdersView extends BaseStatefulComponent<PurchaseOrdersViewProps, State> {
    constructor(props: PurchaseOrdersViewProps) {
        super(props);
        this.state = {
            appliedFilters: [],
            scrollbarRerenderTrigger: new Date().getTime(),
            defaultTimeRangeValue: DefaultTimeRange.LAST_90_DAYS,
            searchInputValue: '',
            tableHeight: 0,
        };
    }

    tableRef = React.createRef<HTMLTableElement>();

    debounceDoFilter = debounce((filter?: Omit<TableFilter<any>, 'onSelectChangeCallback' | 'tagSelectType'>) => {
        this.props.filterPurchaseOrders(parseFilters(filter), filter);
        // FIXME: Why this line is needed? Selector from action not using anywhere
        // this.props.setUserTouchedPOListStatusFilter(filter.values.length > 0);
    }, 400);

    debounceDoSearch = debounce((searchString?: string) => {
        this.props.searchPurchaseOrders(!isEmpty(searchString) ? searchString : null, false);
    }, 400);

    isDateRangeValuesEmpty = (value: DateRangeValues['value']) => {
        return Boolean(!value?.[0] && !value?.[1]);
    };

    isFilterActive = (columnName: string): TableFilter<any> => {
        const { searchParams, t } = this.props;
        const filters = searchParams && parseRestrictions(searchParams, t);
        return filters.find((filter: TableFilter<any>) => filter.columnName === columnName);
    };

    getFilterValue = (columnName: 'SupplierId' | 'OrderStatus' = 'SupplierId'): TagSelectItem<any>[] => {
        const { Restrictions } = this.props.searchParams;

        if (!Restrictions) {
            return [];
        }

        const filterValues = Restrictions.find((el) => el.Field === columnName);
        const normalizedValues = filterValues?.Value ? [filterValues.Value] : filterValues?.Values || [];

        let result: TagSelectItem<any>[] = normalizedValues.map((el) => {
            return { value: el, text: el };
        });

        if (columnName === 'OrderStatus') {
            const extraStatuses = Restrictions.find((el) => el.Field === 'OrderExtraStatus');

            result = result.map((el) => {
                if (el.value !== String(PurchaseOrderStatus.Confirmed)) {
                    return el;
                }
                const item: TagSelectItem<any> = { ...el };
                if (!extraStatuses?.Values?.length) {
                    return item;
                }
                item.children = extraStatuses.Values.map((status) => ({ value: Number(status), text: status })).filter(
                    (extraStatus) => extraStatus.value !== Number(PurchaseOrderExtraStatus.Canceled),
                );

                return item;
            });
            const canceledStatus = extraStatuses?.Values?.find((status) => status === String(PurchaseOrderExtraStatus.Canceled));
            if (canceledStatus) {
                result.push({ value: `${PurchaseOrderExtraStatus.Canceled}-extra`, text: null });
            }
            result = result.filter((el) => Boolean(el.children === undefined) || Boolean(el.children.length));
        }
        return result;
    };

    isSearchValueSame = () => {
        const searchValue = this.getSearchInputValueFromRestrictions();
        return searchValue === this.state.searchInputValue;
    };

    getCurrentDateRange = () => {
        const dateRangeRestriction = this.props.searchParams.Restrictions.find((r: any) => r.Field === DATE_RESTRICTION);
        const dateRange = (dateRangeRestriction?.Values || [null, null]).map((el) => (el ? new Date(el) : null)) as [Date, Date];
        const [dateFrom, dateTo] = dateRange;
        const defaultTimeRangeValue = getRangeEnumFromDates(dateFrom, dateTo);
        this.setState({ defaultTimeRangeValue });
        return dateRange;
    };

    getSearchInputValueFromRestrictions() {
        const searchRestriction = this.props.searchParams.Restrictions.find((r) => r.Field === DEFAULT_RESTRICTION);
        return searchRestriction ? searchRestriction.Value : null;
    }

    getSavedColumnsSettings = () => {
        const groupMember = this.props.groupMember.payload;
        const foundSettings = groupMember?.UserSettings?.find((s) => s.Name === UserSettingName.PURCHASE_ORDERS_COLUMNS_CONFIG);
        return foundSettings?.Value && JSON.parse(foundSettings.Value);
    };

    handleClickOpenPoDetails = (id: number) => () => {
        eventTrack({
            event: 'purchase_orders_table',
            label: 'Open Purchase Order',
        });
        window.location.href = `#/purchase-orders/details/${id}`;
    };

    handleClickResetFilters = () => {
        eventTrack({
            event: 'purchase_orders_table',
            label: 'Clear all filters',
        });
        this.setState({ defaultTimeRangeValue: null, searchInputValue: '' });
        this.props.searchPurchaseOrders(undefined, true);
    };

    handleChangeDateRange = (v: DateRangeValues) => {
        eventTrack({
            event: 'purchase_orders_table',
            label: 'Select date filter',
        });
        const filter: Restriction = {
            Field: DATE_RESTRICTION,
            Value: null,
            Values: v.value,
            FieldSearchType: SearchType.NotSelected,
        };

        this.setState({ defaultTimeRangeValue: v.range });
        this.props.filterPurchaseOrders(filter, null);
    };

    handleChangeFilter: FilterItemSelectProps['onChange'] = debounce(({ items, columnName, columnExtraName }) => {
        eventTrack({
            event: 'purchase_orders_table',
            label: columnName === 'SupplierId' ? 'Select supplier filter' : 'Select status filter',
        });

        const filters: Omit<TableFilter<any>, 'onSelectChangeCallback' | 'tagSelectType'> = {
            columnName,
            values: items,
        };
        if (columnExtraName) {
            filters.childColumnName = columnExtraName;
        }
        const activeFilters = this.isFilterActive(columnName);
        !activeFilters
            ? this.debounceDoFilter(filters)
            : this.debounceDoFilter({
                  ...activeFilters,
                  values: items,
              });
    }, 400);

    handleClickDateRangeClear: React.MouseEventHandler<HTMLElement> = (e) => {
        e.stopPropagation();
        this.handleChangeDateRange({ value: [null, null], range: null });
    };

    handleChangeSearchInput: React.ChangeEventHandler<HTMLInputElement> = (e) => {
        this.setState({ searchInputValue: e.target.value });
    };

    handleKeyUpSearchInput: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
        if (e.key === 'Enter' && !this.isSearchValueSame()) {
            eventTrack({
                event: 'purchase_orders_table',
                label: 'Search Purchase order',
            });
            this.debounceDoSearch(e.currentTarget.value);
        }
    };

    handleClickSearchInputClear = () => {
        eventTrack({
            event: 'purchase_orders_table',
            label: 'Clear search input',
        });
        const isFilterActive = this.getSearchInputValueFromRestrictions();
        const searchInputValue = '';
        this.setState({ searchInputValue });
        if (isFilterActive) {
            this.debounceDoSearch(searchInputValue);
        }
    };

    handleClickExportPoToXlsx = () => {
        eventTrack({
            event: 'purchase_orders_table',
            label: 'Export Purchase Orders',
        });
        this.props.exportPurchaseOrdersToXLSX();
    };

    handleChangePage = (page: number, pageSize?: number) => {
        if (pageSize !== this.props.searchParams?.PagingOptions.Count) {
            eventTrack({
                event: 'purchase_orders_table',
                label: 'Set page size',
            });
        }
        this.props.setPurchaseOrdersPagingOptions(page, pageSize);
    };

    handleUpdateColumnsSettings: TableColumnsConfigurableContextProps['onSettingsUpdate'] = (newColumns) => {
        this.props.setTableColumnsConfigurable(newColumns);
    };

    handleClickAddNewPo = () => {
        eventTrack({
            event: 'purchase_orders_table',
            label: 'Add new Purchase Order',
        });
        this.props.history.push('/purchase-orders/add');
    };

    renderExportButton = () => {
        const isDisabled = !this.props.purchaseOrdersLoadable.payload?.Items?.length;
        const button = (
            <Button
                className="content-block__header-text-button"
                buttonType={ButtonType.HEADER_SECONDARY}
                icon={ICONS.EXPORT_24}
                disabled={isDisabled}
                onClick={this.handleClickExportPoToXlsx}
                iconPlacement={ButtonIconPlacement.LEFT}
                dataId="export-xlsx-button"
            >
                {this.props.t('view.PurchaseOrders.exportXlsx')}
            </Button>
        );

        return isDisabled ? (
            <Tooltip content={this.props.t('view.PurchaseOrders.nothingToExport')}>
                <span>{button}</span>
            </Tooltip>
        ) : (
            button
        );
    };

    handleColumnWidthChange = (columnName: string, width: number) => {
        const columnsConfig = this.getSavedColumnsSettings() || defaultPOColumns;
        const newColumns = columnsConfig?.map((col: TableColumnsConfigurableItem) => (col.id === columnName ? { ...col, width } : col));
        this.handleUpdateColumnsSettings(newColumns);
    };

    componentDidMount() {
        this.props.getPurchaseOrdersList(undefined);
        if (!isAuthorized(Role.CanViewPurchaseOrders, this.props.userData)) {
            this.props.history.push('/dashboard');
        }
        const storedSearchValue = this.getSearchInputValueFromRestrictions();
        if (storedSearchValue?.length && this.state.searchInputValue !== storedSearchValue) {
            this.setState({ searchInputValue: storedSearchValue });
        }
    }

    componentDidUpdate(prevProps: Readonly<Props & DispatchProps & WithTranslation & RouteComponentProps<RouteParams>>): void {
        const { searchParams, sideMenuIsOpen, purchaseOrdersLoadable, t } = prevProps;
        const itemsLength = this.props.purchaseOrdersLoadable?.payload?.Items?.length;
        const updateTableHeight = itemsLength && (purchaseOrdersLoadable?.payload?.Items?.length !== itemsLength || this.state.tableHeight === 0);
        if (updateTableHeight) {
            this.setState({
                tableHeight: this.tableRef?.current?.offsetHeight,
            });
        }
        if (sideMenuIsOpen !== this.props.sideMenuIsOpen) {
            setTimeout(() => {
                this.setState({
                    scrollbarRerenderTrigger: new Date().getTime(),
                });
            }, 300);
        }

        const filters = (searchParams && parseRestrictions(searchParams, t)) || [];

        if (!isEqual(this.state.appliedFilters, filters)) {
            this.setState({
                appliedFilters: filters,
            });
        }
    }

    render() {
        const { searchParams, purchaseOrdersLoadable, sortPurchaseOrders, deletePurchaseOrder, duplicatePurchaseOrder, exportPurchaseOrderToPdf, t } = this.props;
        const savedColumnsSettings = this.getSavedColumnsSettings();
        const dateRange = this.getCurrentDateRange();
        const isDateRangeEmpty = this.isDateRangeValuesEmpty(dateRange);
        const storedSearchInputValue = this.getSearchInputValueFromRestrictions();
        const searchInputValue = this.state.searchInputValue;
        const rangeLabel = isDateRangeEmpty
            ? t('view.PurchaseOrders.DateCreated')
            : this.state.defaultTimeRangeValue === DefaultTimeRange.CUSTOM
            ? `${formatDate(dateRange?.[0])} - ${formatDate(dateRange?.[1])}`
            : getRangeLabel(this.state.defaultTimeRangeValue, t);

        const contentBlockTitle = `${t('view.PurchaseOrders.Title')} (${(purchaseOrdersLoadable.payload && purchaseOrdersLoadable.payload.TotalCount) || 0})`;
        const isShowClearFiltersButton = Boolean(
            this.state.appliedFilters?.length || !isDateRangeEmpty || storedSearchInputValue?.length || this.getFilterValue('SupplierId')?.length || this.getFilterValue('OrderStatus')?.length,
        );
        const isShowClearSearchInput = Boolean(searchInputValue || storedSearchInputValue);
        const isShowSearchHelper = Boolean((searchInputValue || storedSearchInputValue) && !this.isSearchValueSame());

        return (
            <MainPage className="purchase-orders" type={MainPageType.WIDE}>
                <ContentBlock>
                    <ContentBlockHeader dataId={'purchaseOrderHeader'}>
                        <div className="content-block__header-heading">
                            <ContentBlockHeading dataId={createDataId('purchaseOrderHeader', 'title')} type={ContentBlockHeadingType.BOLD}>
                                <Typography variant="h1" element="span">
                                    {contentBlockTitle}
                                </Typography>
                            </ContentBlockHeading>
                            <div className="content-block__header-export-button-wrapper">{this.renderExportButton()}</div>
                            <AccessLevel role={Role.CanAddPurchaseOrders}>
                                <Tooltip content={t('view.PurchaseOrders.AddNew')}>
                                    <Button
                                        className="content-block__header-text-button"
                                        buttonType={ButtonType.HEADER_PRIMARY}
                                        onClick={this.handleClickAddNewPo}
                                        icon={ICONS.ADD_24}
                                        iconPlacement={ButtonIconPlacement.LEFT}
                                        dataId={'add-po-link'}
                                    >
                                        {t('views.global.Add_New')}
                                    </Button>
                                </Tooltip>
                            </AccessLevel>
                        </div>
                        <div className="po-filters">
                            <div className={classNames('po-filters__input', { 'po-filters__input--has-focus': Boolean(isShowClearSearchInput) })}>
                                <TextInput
                                    placeholder={t('component.search.action')}
                                    dataId={createDataId('purchaseOrdersSearch', 'search-input')}
                                    onChange={this.handleChangeSearchInput}
                                    onKeyUp={this.handleKeyUpSearchInput}
                                    value={searchInputValue}
                                    type={TextInputType.COMPACT}
                                    icon={ICONS.SEARCH}
                                    addonAfter={
                                        <div className="po-filters__input__addon-content">
                                            {isShowSearchHelper && <span>{t('component.ContentBlock.SearchInput.PressEnter')}</span>}
                                            {isShowClearSearchInput && <Button buttonType={ButtonType.ICON} icon={ICONS.CLOSE_24} onClick={this.handleClickSearchInputClear} />}
                                        </div>
                                    }
                                />
                            </div>

                            <DateRangeFilter
                                classNameModal="po-date-picker"
                                dataId={createDataId('purchaseOrderHeader', 'date-range')}
                                label={t('views.global.dateCreated')}
                                datePickerActiveScope={'dateCreated'}
                                dateRange={dateRange}
                                placeholder={t('component.invoiceFilter.customDates')}
                                onChange={this.handleChangeDateRange}
                                commitOnChange
                                showDoubleView
                                render={(props) => {
                                    return (
                                        <Button
                                            buttonType={ButtonType.ICON_TEXT}
                                            className={classNames('po-filter-item', {
                                                'po-filter-item__active': !isDateRangeEmpty,
                                                'po-filter-item__active__open': props.modal.isOpen,
                                            })}
                                            onClick={props.modal.open}
                                        >
                                            {rangeLabel}
                                            {!isDateRangeEmpty && (
                                                <a className="po-date-picker__button__reset" onClick={this.handleClickDateRangeClear}>
                                                    <Icon iconName={ICONS.CLOSE_24} />
                                                </a>
                                            )}
                                        </Button>
                                    );
                                }}
                            />
                            <FilterItemSelect
                                columnName="SupplierId"
                                onChange={this.handleChangeFilter}
                                values={this.getFilterValue('SupplierId')}
                                loadItems={getSupplierList}
                                dataId={createDataId('purchaseOrderFilters', 'filterItem', 'supplierId')}
                                label={t('view.PurchaseOrders.Supplier')}
                            />

                            <FilterItemSelect
                                columnName="OrderStatus"
                                columnExtraName="OrderExtraStatus"
                                onChange={this.handleChangeFilter}
                                values={this.getFilterValue('OrderStatus')}
                                dataId={createDataId('purchaseOrderFilters', 'filterItem', 'orderStatus')}
                                items={getStatusItemsArray(t)}
                                label={t('view.PurchaseOrders.Status')}
                            />
                            {Boolean(isShowClearFiltersButton) && (
                                <Button buttonType={ButtonType.ICON_SQUARE} icon={ICONS.CLOSE_24} className="po-filter-item po-filters__button__clear-filters" onClick={this.handleClickResetFilters} />
                            )}
                        </div>
                    </ContentBlockHeader>

                    <TableColumnsConfigurableProvider savedConfig={savedColumnsSettings} defaultConfig={defaultPOColumns} onSettingsUpdate={this.handleUpdateColumnsSettings}>
                        <div className="purchase-orders__table" data-id={'purchaseOrderTable'}>
                            {Boolean(purchaseOrdersLoadable.payload?.Items?.length) && (
                                <TableSettingsModal translationsMap={translationsKeys} dataId={createDataId('purchase-orders-modal', 'columns-config')} eventTrackName="purchase_orders_table" />
                            )}
                            <Scrollbars autoHeightMax="100%" hideTracksWhenNotNeeded name={this.state.scrollbarRerenderTrigger.toString()}>
                                <ContentBlockBody className="purchase-orders__table-content" loading={purchaseOrdersLoadable.loading} dataId={'purchaseOrderTableContent'}>
                                    {purchaseOrdersLoadable.loading || purchaseOrdersLoadable.payload?.Items.length ? (
                                        <Table forwardRef={this.tableRef}>
                                            <DataTableHeader data-id={createDataId('purchaseOrders', 'Table', 'Header')}>
                                                <TagSelectRepositionerContext.Provider value={this.props.purchaseOrdersLoadable.payload}>
                                                    <TableRow>
                                                        <TableRowConfigurable>
                                                            <TableHead
                                                                resizable
                                                                searchParams={searchParams}
                                                                columnName={PO_COLUMN_ID.ORDER_NUMBER}
                                                                onClick={sortPurchaseOrders}
                                                                sortable
                                                                tableHeight={this.state.tableHeight}
                                                                minColumnWidth={MIN_TABLE_COLUMN_WIDTH + 20}
                                                                handleColumnWidthChange={this.handleColumnWidthChange}
                                                                dataId={createDataId('purchase-orders-thead-sort', `number`)}
                                                            >
                                                                {t('view.PurchaseOrders.Number')}
                                                            </TableHead>
                                                            <TableHead
                                                                resizable
                                                                searchParams={searchParams}
                                                                columnName={PO_COLUMN_ID.SUPPLIER_NAME}
                                                                onClick={sortPurchaseOrders}
                                                                dataId={createDataId('purchase-orders-thead-sort', 'supplier')}
                                                                sortable
                                                                minColumnWidth={MIN_TABLE_COLUMN_WIDTH}
                                                                tableHeight={this.state.tableHeight}
                                                                handleColumnWidthChange={this.handleColumnWidthChange}
                                                            >
                                                                {t('view.PurchaseOrders.Supplier')}
                                                            </TableHead>

                                                            <TableHead
                                                                resizable
                                                                searchParams={purchaseOrdersLoadable.request}
                                                                columnName={PO_COLUMN_ID.ORDER_STATUS}
                                                                onClick={sortPurchaseOrders}
                                                                dataId={createDataId('purchase-orders-thead-sort', 'status')}
                                                                sortable
                                                                minColumnWidth={MIN_TABLE_COLUMN_WIDTH}
                                                                tableHeight={this.state.tableHeight}
                                                                handleColumnWidthChange={this.handleColumnWidthChange}
                                                            >
                                                                {t('view.PurchaseOrders.Status')}
                                                            </TableHead>
                                                            <TableHead
                                                                resizable
                                                                dataId={createDataId('purchase-orders-thead-sort', 'decision-date')}
                                                                columnName={PO_COLUMN_ID.DECISION_DATE}
                                                                onClick={sortPurchaseOrders}
                                                                sortable
                                                                minColumnWidth={MIN_TABLE_COLUMN_WIDTH}
                                                                tableHeight={this.state.tableHeight}
                                                                handleColumnWidthChange={this.handleColumnWidthChange}
                                                                searchParams={purchaseOrdersLoadable.request}
                                                            >
                                                                <Tooltip
                                                                    content={<div className="purchase-orders__tooltip-decision-date">{t('view.PurchaseOrders.Tooltip.DecisionDate')}</div>}
                                                                    boundary="viewport"
                                                                >
                                                                    <span>{t('view.PurchaseOrders.DecisionDate')}</span>
                                                                </Tooltip>
                                                            </TableHead>
                                                            <TableHead
                                                                resizable
                                                                dataId={createDataId('purchase-orders-thead-sort', 'approver')}
                                                                columnName={PO_COLUMN_ID.APPROVER}
                                                                minColumnWidth={MIN_TABLE_COLUMN_WIDTH}
                                                                tableHeight={this.state.tableHeight}
                                                                handleColumnWidthChange={this.handleColumnWidthChange}
                                                            >
                                                                <Tooltip content={t('view.PurchaseOrders.Tooltip.Approver')} boundary="viewport">
                                                                    <span>{t('view.PurchaseOrders.Approver')}</span>
                                                                </Tooltip>
                                                            </TableHead>
                                                            <TableHead
                                                                resizable
                                                                searchParams={purchaseOrdersLoadable.request}
                                                                columnName={PO_COLUMN_ID.DESCRIPTION}
                                                                onClick={sortPurchaseOrders}
                                                                dataId={createDataId('purchase-orders-thead-sort', 'description')}
                                                                sortable
                                                                minColumnWidth={MIN_TABLE_COLUMN_WIDTH}
                                                                tableHeight={this.state.tableHeight}
                                                                handleColumnWidthChange={this.handleColumnWidthChange}
                                                            >
                                                                {t('view.PurchaseOrders.Description')}
                                                            </TableHead>
                                                            <TableHead
                                                                resizable
                                                                searchParams={purchaseOrdersLoadable.request}
                                                                columnName={PO_COLUMN_ID.DATE_CREATED}
                                                                onClick={sortPurchaseOrders}
                                                                dataId={createDataId('purchase-orders-thead-sort', 'created')}
                                                                sortable
                                                                minColumnWidth={MIN_TABLE_COLUMN_WIDTH}
                                                                tableHeight={this.state.tableHeight}
                                                                handleColumnWidthChange={this.handleColumnWidthChange}
                                                            >
                                                                <Tooltip content={t('view.PurchaseOrders.Tooltip.DateCreated')} boundary="viewport">
                                                                    <span>{t('view.PurchaseOrders.DateCreated')}</span>
                                                                </Tooltip>
                                                            </TableHead>
                                                            <TableHead
                                                                resizable
                                                                dataId={createDataId('purchase-orders-thead-sort', 'createdBy')}
                                                                columnName={PO_COLUMN_ID.CREATED_BY}
                                                                minColumnWidth={MIN_TABLE_COLUMN_WIDTH}
                                                                tableHeight={this.state.tableHeight}
                                                                handleColumnWidthChange={this.handleColumnWidthChange}
                                                            >
                                                                <Tooltip content={t('view.PurchaseOrders.Tooltip.CreatedBy')} boundary="viewport">
                                                                    <span>{t('view.PurchaseOrders.CreatedBy')}</span>
                                                                </Tooltip>
                                                            </TableHead>
                                                            <TableHead
                                                                resizable
                                                                dataId={createDataId('purchase-orders-thead-sort', `fulfilment`)}
                                                                columnName={PO_COLUMN_ID.FULFILMENT}
                                                                minColumnWidth={MIN_TABLE_COLUMN_WIDTH}
                                                                tableHeight={this.state.tableHeight}
                                                                handleColumnWidthChange={this.handleColumnWidthChange}
                                                            >
                                                                <Tooltip content={t('view.PurchaseOrders.Tooltip.Fulfilment')} boundary="viewport">
                                                                    <span>{t('view.PurchaseOrders.Fulfilment')}</span>
                                                                </Tooltip>
                                                            </TableHead>
                                                            <TableHead
                                                                resizable
                                                                dataId={createDataId('purchase-orders-thead-sort', 'remaining-sum')}
                                                                columnName={PO_COLUMN_ID.REMAINING_SUM}
                                                                onClick={sortPurchaseOrders}
                                                                sortable
                                                                minColumnWidth={MIN_TABLE_COLUMN_WIDTH}
                                                                tableHeight={this.state.tableHeight}
                                                                handleColumnWidthChange={this.handleColumnWidthChange}
                                                                className="purchase-orders__table-content__align__right"
                                                                searchParams={purchaseOrdersLoadable.request}
                                                            >
                                                                <Tooltip content={t('view.PurchaseOrders.Tooltip.RemainingSum')} boundary="viewport">
                                                                    <span>{t('view.PurchaseOrders.RemainingSum')}</span>
                                                                </Tooltip>
                                                            </TableHead>
                                                            <TableHead
                                                                resizable
                                                                columnName={PO_COLUMN_ID.TYPE}
                                                                onClick={sortPurchaseOrders}
                                                                sortable
                                                                minColumnWidth={MIN_TABLE_COLUMN_WIDTH}
                                                                tableHeight={this.state.tableHeight}
                                                                handleColumnWidthChange={this.handleColumnWidthChange}
                                                                searchParams={purchaseOrdersLoadable.request}
                                                            >
                                                                {t('view.PurchaseOrders.Type')}
                                                            </TableHead>
                                                            <TableHead
                                                                resizable
                                                                searchParams={purchaseOrdersLoadable.request}
                                                                columnName={PO_COLUMN_ID.NET_TOTAL}
                                                                onClick={sortPurchaseOrders}
                                                                dataId={createDataId('purchase-orders-thead-sort', 'net-total')}
                                                                sortable
                                                                minColumnWidth={MIN_TABLE_COLUMN_WIDTH}
                                                                tableHeight={this.state.tableHeight}
                                                                handleColumnWidthChange={this.handleColumnWidthChange}
                                                                className="purchase-orders__table-content__align__right"
                                                            >
                                                                {t('view.PurchaseOrders.Rows.NetTotal')}
                                                            </TableHead>
                                                            <TableHead
                                                                resizable
                                                                searchParams={purchaseOrdersLoadable.request}
                                                                columnName={PO_COLUMN_ID.TOTAL}
                                                                onClick={sortPurchaseOrders}
                                                                dataId={createDataId('purchase-orders-thead-sort', 'total')}
                                                                sortable
                                                                minColumnWidth={MIN_TABLE_COLUMN_WIDTH}
                                                                tableHeight={this.state.tableHeight}
                                                                handleColumnWidthChange={this.handleColumnWidthChange}
                                                                className="purchase-orders__table-content__align__right"
                                                            >
                                                                {t('view.PurchaseOrders.SumTotal')}
                                                            </TableHead>
                                                            <TableHead
                                                                resizable
                                                                dataId={createDataId('purchase-orders-thead-sort', 'linked-invoices')}
                                                                columnName={PO_COLUMN_ID.LINKED_INVOICES}
                                                                minColumnWidth={MIN_TABLE_COLUMN_WIDTH}
                                                                tableHeight={this.state.tableHeight}
                                                                handleColumnWidthChange={this.handleColumnWidthChange}
                                                                className="purchase-orders__table-content__align__right"
                                                            >
                                                                <Tooltip content={t('view.PurchaseOrders.Tooltip.LinkedInvoices')} boundary="viewport">
                                                                    <span>{t('view.PurchaseOrders.LinkedInvoices')}</span>
                                                                </Tooltip>
                                                            </TableHead>
                                                        </TableRowConfigurable>
                                                        <TableHead className={'btn-wrapper'}>{'\u00A0'}</TableHead>
                                                    </TableRow>
                                                </TagSelectRepositionerContext.Provider>
                                            </DataTableHeader>
                                            <TableBody>
                                                {purchaseOrdersLoadable.payload?.Items?.map((po, id) => (
                                                    <TableRow key={id} data-id={createDataId('purchaseOrdersItem', 'row', `${id}`)} onClick={this.handleClickOpenPoDetails(po.Id)}>
                                                        <TableRowConfigurable>
                                                            <TableCell dataId={createDataId('purchaseOrdersItem', 'number', `${id}`)} columnName={PO_COLUMN_ID.ORDER_NUMBER}>
                                                                {po.OrderNumber}
                                                            </TableCell>
                                                            <TableCell dataId={createDataId('purchaseOrdersItem', 'supplier', `${id}`)} columnName={PO_COLUMN_ID.SUPPLIER_NAME}>
                                                                {po.Supplier ? po.Supplier.Name : '\u00A0'}
                                                            </TableCell>

                                                            <TableCell dataId={createDataId('purchaseOrdersItem', 'status', `${id}`)} columnName={PO_COLUMN_ID.ORDER_STATUS}>
                                                                <PoStatusChip status={po.OrderStatus} />
                                                            </TableCell>
                                                            <TableCell dataId={createDataId('purchaseOrdersItem', 'status-data', `${id}`)} columnName={PO_COLUMN_ID.DECISION_DATE}>
                                                                {getDecisionDate(po)}
                                                            </TableCell>
                                                            <TableCell dataId={createDataId('purchaseOrdersItem', 'approver', `${id}`)} columnName={PO_COLUMN_ID.APPROVER}>
                                                                {getApprovers(po)}
                                                            </TableCell>
                                                            <TableCell dataId={createDataId('purchaseOrdersItem', 'description', `${id}`)} columnName={PO_COLUMN_ID.DESCRIPTION}>
                                                                {po.Description || '\u00A0'}
                                                            </TableCell>
                                                            <TableCell dataId={createDataId('purchaseOrdersItem', 'created', `${id}`)} columnName={PO_COLUMN_ID.DATE_CREATED}>
                                                                {formatDate(po.DateCreated)}
                                                            </TableCell>
                                                            <TableCell dataId={createDataId('purchaseOrdersItem', 'createdBy', `${id}`)} columnName={PO_COLUMN_ID.CREATED_BY}>
                                                                {po.CreatedByName || t('view.PurchaseOrders.CreatedBy.Import')}
                                                            </TableCell>
                                                            <TableCell dataId={createDataId('purchaseOrdersItem', 'fulfilment', `${id}`)} columnName={PO_COLUMN_ID.FULFILMENT}>
                                                                <PoFulfilmentView purchaseOrder={po} />
                                                            </TableCell>
                                                            <TableCell
                                                                dataId={createDataId('purchaseOrdersItem', 'remaining-sum', `${id}`)}
                                                                columnName={PO_COLUMN_ID.REMAINING_SUM}
                                                                className="purchase-orders__table-content__align__right"
                                                            >
                                                                {po.LinkedInvoicesSum !== null
                                                                    ? formatMoneyAndCurrency(po.RemainingSum, po.Currency || DEFAULT_CURRENCY_PLACEHOLDER)
                                                                    : DEFAULT_CURRENCY_PLACEHOLDER}
                                                            </TableCell>
                                                            <TableCell dataId={createDataId('purchaseOrdersItem', 'type', `${id}`)} columnName={PO_COLUMN_ID.TYPE}>
                                                                {t(getTypeKey(po.OrderType))}
                                                            </TableCell>
                                                            <TableCell
                                                                dataId={createDataId('purchaseOrdersItem', 'netTotal', `${id}`)}
                                                                columnName={PO_COLUMN_ID.NET_TOTAL}
                                                                className="purchase-orders__table-content__align__right"
                                                            >
                                                                {formatMoneyAndCurrency(po.TotalWithoutVat, po.Currency)}
                                                            </TableCell>
                                                            <TableCell
                                                                dataId={createDataId('purchaseOrdersItem', 'total', `${id}`)}
                                                                columnName={PO_COLUMN_ID.TOTAL}
                                                                className="purchase-orders__table-content__align__right"
                                                            >
                                                                {formatMoneyAndCurrency(po.Total, po.Currency)}
                                                            </TableCell>
                                                            <TableCell
                                                                dataId={createDataId('purchaseOrdersItem', 'linked-invoices', `${id}`)}
                                                                columnName={PO_COLUMN_ID.LINKED_INVOICES}
                                                                className="purchase-orders__table-content__align__right"
                                                            >
                                                                {po?.LinkedInvoicesCount || '-'}
                                                            </TableCell>
                                                        </TableRowConfigurable>
                                                        <TableCell
                                                            className="btn-wrapper"
                                                            dataId={createDataId('purchaseOrdersItem', 'delete', `${id}`)}
                                                            responsiveCellType={ResponsiveCellType.ACTIONS}
                                                            nowrap
                                                            onClick={(e: React.MouseEvent<HTMLTableCellElement, MouseEvent>) => e.stopPropagation()}
                                                        >
                                                            <PurchaseOrdersViewItemMenu
                                                                dataId={createDataId('purchaseOrdersItem', `${id}`)}
                                                                isReadOnly={false}
                                                                purchaseOrderId={po.Id}
                                                                t={t}
                                                                deletePurchaseOrder={deletePurchaseOrder}
                                                                exportPurchaseOrderToPdf={exportPurchaseOrderToPdf}
                                                                OrderNumber={po.OrderNumber}
                                                                duplicatePurchaseOrder={duplicatePurchaseOrder}
                                                            />
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    ) : (
                                        <EmptySearchResults
                                            title={t('component.emptySearchResults.noResultsFound')}
                                            subTitle={t('component.emptySearchResults.askSupport')}
                                            resetFiltersCb={this.handleClickResetFilters}
                                        />
                                    )}
                                </ContentBlockBody>
                            </Scrollbars>
                        </div>
                    </TableColumnsConfigurableProvider>
                    {!!purchaseOrdersLoadable.payload?.Items?.length && (
                        <ContentBlockFooter type={ContentBlockFooterType.PAGER}>
                            <Pager pages={purchaseOrdersLoadable.payload} onPageChange={this.handleChangePage} />
                        </ContentBlockFooter>
                    )}
                </ContentBlock>
            </MainPage>
        );
    }
}

export default PurchaseOrdersView;
