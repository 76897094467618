export function isGrantThornton(): boolean {
    return window.location.href.indexOf('accounting.grantthornton.sk') !== -1;
}
export function is1to1(): boolean {
    return window.location.href.indexOf('1to1.fitek.com') !== -1;
}
export function isSuf(): boolean {
    return window.location.href.indexOf('suf.gov.rs') !== -1;
}
export function isSufs(): boolean {
    return window.location.href.indexOf('suf.gov.rs') !== -1 || window.location.href.indexOf('uat.suf.gov.rs') !== -1 || window.location.href.indexOf('demo.suf.gov.rs') !== -1;
}
export function isUnifiedpost(): boolean {
    return window.location.href.indexOf('unifiedpost') !== -1;
}
export function isRiga(): boolean {
    return window.location.href.indexOf('apriteriga.fitekin.com') !== -1;
}
export function isMaxima(): boolean {
    return window.location.href.indexOf('fitekin.maxima.lt') !== -1 || window.location.href.indexOf('testmaxima.fitekin.com') !== -1;
}
export function isSwedbank(): boolean {
    return window.location.href.indexOf('swedbank.fitekin.com') !== -1;
}

export function getCustomAzureLoginRedirectUrl(): string | null {
    if (isMaxima()) {
        return 'https://fitekin.maxima.lt/AzureLogin';
    } else if (isSwedbank()) {
        return 'https://swedbank.fitekin.com/AzureLogin';
    }
    return null;
}

export const isOnlyAzureLogin = () => isMaxima() || isSwedbank();

export function getGeneralAzureLoginRedirectUrl(): string | null {
    // No link for Serbian environment for now
    if (!isSuf()) {
        return `${window.location.origin}/AzureLogin`;
    }
    return null;
}

export const isUsingGoogleAnalytics = !isSuf();

export const isAWhiteLabel = is1to1() || isGrantThornton() || isSuf() || isMaxima() || isRiga();

export function getWhitelabelPageTitle() {
    if (isGrantThornton()) {
        return 'GrantThornton';
    } else if (is1to1()) {
        return '1to1';
    } else if (isSuf()) {
        return 'SUF';
    } else if (isRiga()) {
        return 'Riga City Council';
    } else if (isMaxima()) {
        return 'Maxima';
    } else {
        return 'FitekIN';
    }
}

export function getWhitelabelParameter() {
    if (isGrantThornton()) {
        return 'grantthornton';
    } else if (is1to1()) {
        return '1to1';
    } else if (isSuf()) {
        return 'suf.gov';
    } else if (isRiga()) {
        return 'rigacitycouncil';
    } else if (isMaxima()) {
        return 'maxima';
    } else {
        return 'unifiedpost';
    }
}

export function getWhitelabelFavicon() {
    if (isGrantThornton()) {
        return 'grantthornton.ico';
    }
    if (isRiga()) {
        return 'riga.ico';
    }
    if (isMaxima()) {
        return 'maxima.ico';
    }
    if (is1to1() || isSuf()) {
        return 'blank.ico';
    }
    const usesDarkMode = window.matchMedia('(prefers-color-scheme: dark)').matches || false;
    return usesDarkMode ? 'favicon-dark.ico' : 'favicon.ico';
}
