import { createSelector } from 'reselect';
import { UserSettingName } from '../../services/types/ApiTypes';

import { GlobalState } from '../../rootReducer';
import { getUserSettingValue } from './userSettingUtil';

export const getCurrentUser = (state: GlobalState) => state.user.currentUser;
export const getCurrentUserLoaded = (state: GlobalState) => state.user.currentUserLoaded;
export const getCurrencies = (state: GlobalState) => state.user.currencies.map((c) => c.Code);
export const selectCurrencies = (state: GlobalState) => state.user.currencies;
export const getCurrentCompany = (state: GlobalState) => state.user.currentCompany;
export const getCurrentCompanyName = (state: GlobalState) => state.user.currentCompany?.CompanyName || '';
export const selectUserCompanies = (state: GlobalState) => state.user.userCompanies;
export const selectUserCompaniesSearchResult = (state: GlobalState) => state.user.userCompaniesSearchResult;
export const selectNoMoreUserCompaniesSearchResults = (state: GlobalState) => state.user.noMoreUserCompaniesSearchResults;
export const selectUserActiveVatCodes = (state: GlobalState) => state.user.vatCodes;
export const getCompanyAccounts = (state: GlobalState) => state.user.accounts;
export const getCompanyCustomCostObjectives = (state: GlobalState) => state.user.customCostObjectives;
export const getCompanyWorkflowTemplates = (state: GlobalState) => state.user.currentCompany && state.user.currentCompany.WorkflowTemplates;
export const getWaitingInvoicesCountAreUpdating = (state: GlobalState) => state.user.areWaitingInvoicesCountsUpdating;

export const selectBoCurrentUserLoadable = (state: GlobalState) => state.boUser.boCurrentUserLoadable;
export const getCurrenUserGroupMemberLoadable = (state: GlobalState) => state.user.groupMemberLoadable;
export const getCurrenUserGroupMemberCommonLoadable = (state: GlobalState) => state.user.groupMemberCommonLoadable;
export const selectCurrentCompanyGuid = createSelector(getCurrentCompany, (currentCompany) => currentCompany?.CompanyGuid);
export const getUserCompaniesTotalCount = (state: GlobalState) => state.user.userCompaniesTotalCount;
export const getUserCompaniesNotificationsPagingOptions = (state: GlobalState) => state.user.userCompaniesNotificationsPagingOptions;
export const selectAskToVerifyEmail = createSelector(selectBoCurrentUserLoadable, (selectBoCurrentUserLoadable) => {
    if (
        selectBoCurrentUserLoadable?.loaded &&
        selectBoCurrentUserLoadable.payload?.EmailLastValidated === null &&
        !(!selectBoCurrentUserLoadable.payload.Email && selectBoCurrentUserLoadable.payload?.PersonalCode)
    ) {
        return true;
    }
    return false;
});

export const showEmailVerifiedNotification = createSelector(
    selectBoCurrentUserLoadable,
    getCurrenUserGroupMemberCommonLoadable,
    (selectBoCurrentUserLoadable, getCurrenUserGroupMemberCommonLoadable) => {
        if (
            selectBoCurrentUserLoadable?.payload?.EmailLastValidated &&
            getUserSettingValue(UserSettingName.IS_EMAIL_VERIFICATION_PENDING, getCurrenUserGroupMemberCommonLoadable?.payload) === 'true'
        ) {
            return true;
        }
        return false;
    },
);

export const isInvoiceRowsCalculationActive = createSelector(getCurrenUserGroupMemberCommonLoadable, (getCurrenUserGroupMemberCommonLoadable) => {
    if (getUserSettingValue(UserSettingName.IS_INVOICE_ROWS_CALCULATION_ACTIVE, getCurrenUserGroupMemberCommonLoadable?.payload) === 'true') {
        return true;
    }
    return false;
});

export const isTransactionRowsCalculationActive = createSelector(getCurrenUserGroupMemberCommonLoadable, (getCurrenUserGroupMemberCommonLoadable) => {
    if (getUserSettingValue(UserSettingName.IS_TRANSACTION_ROWS_CALCULATION_ACTIVE, getCurrenUserGroupMemberCommonLoadable?.payload) === 'true') {
        return true;
    }
    return false;
});
